import React from 'react';
import { v4 } from 'uuid';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import BackgroundImage from '../../components/BackgroundImage';
import LinkComponent from '../../components/LinkComponent';
import { ImageOverlay } from '../../utils';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';
import { HTMLContent } from '../../components/Content';

export const ServicesPageTemplate = ({ image, title, helmet, blocks, textObject }) => {
  const renderBlocks = () =>
    blocks.map((block) => (
      <div className='column is-3' key={v4()}>
        <LinkComponent linkObject={block.linkObject}>
          <BackgroundImage
            classes='services-block hover-zoom'
            imageInfo={block.image}>
            <h2>{block.title}</h2>
          </BackgroundImage>
        </LinkComponent>
      </div>
    ));

  const renderText = () => {
    if (!!textObject) {
      if (textObject.textComponent === "") {
        return null;
      }
      if (!!textObject.textComponent) {
        return (
          <div className='index-text-container' style={{ textAlign: textObject.alignment }}>
            <HTMLContent content={textObject.textComponent} />
          </div>
        );
      }
    } 
    return null;
  }
    
  return (
    <div>
      {helmet || ''}
      <BackgroundImage
        classes={'page-header-image services-page-header'}
        imageInfo={image.image}>
        <h1 className='title page-title'>
          <hr />
          {title}
        </h1>
        <ImageOverlay overlay={image.overlay} />
      </BackgroundImage>

      { renderText() }

      <div className='content columns services-content is-multiline is-gapless'>
        {renderBlocks()}
      </div>
    </div>
  );
};

const ServicesPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <ServicesPageTemplate
        location={location}
        textObject={ frontmatter.textObject }
        title={frontmatter.title}
        image={frontmatter.heroImageObject}
        blocks={frontmatter.blocks}
        content={frontmatter.content}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default ServicesPage;

export const pageQuery = graphql`
  query servicesPageQueryZHHANS {
    markdownRemark(
      frontmatter: { templateKey: { eq: "services-page-zhhans" } }
    ) {
      frontmatter {
        title
        textObject {
          alignment
          textComponent
        }
        heroImageObject {
          overlay
          image
        }
        blocks {
          image
          title
          linkObject {
            isExternal
            link
          }
        }
        content
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
